body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ReactCodeMirror,
.CodeMirror {
  height: 640px;
}

.CodeMirror-scroll {
  position: absolute;
  width: 100%;
}

.Cell-highlight {
  background-color: #fffecb;
}

